<template>
  <div class="container">
    <div class="row" >
      <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/about">Manage Content</router-link> | 
        <a v-if="loggedIn" @click.prevent="logout()" class="text-danger">Logout</a>

      </nav>
      
      <router-view v-if="loggedIn"/>

    
      <div class="mx-auto  rounded rounded-2 my-5 p-4 bg-dark fw-bold text-light" v-else>
        <h1 class="rounded bg-danger">
          You are not logged in!
        </h1>        
        <span class="form-control bg-secondary g-3">
            <input type="text" class="form-control m-1" placeholder="User ID" v-model="user">
            <input type="password" class="form-control m-1" placeholder="Password" v-model="pswd">
            <button type="button" class="btn btn-dark bg-gradient btn-outline-light" @click.prevent="procLogin">login</button>
      </span>
      </div>

    </div>
  </div>
  <!-- <div class="fixed-bottom d-flex justify-content-center p-5" v-if="loggedIn">
    <button class="btn btn-danger btn-toolbar bg-gradient" @click.prevent="logout()">Logout</button>
  </div> -->
</template>
<script>
import axios from 'axios';
import { useFavicon } from '@vueuse/core'
const icon = useFavicon();
export default{
  data(){
    return {
      appName:"Adhikari",
      login:{},
      loggedIn:false,
      user:null,
      pswd:null,
    }
  },
  methods:{
    async logout(){
      await axios.get("/php/logout.php")
              .then(resp =>{
                this.login = resp.data;
                this.loggedIn = this.login.id>0;
                console.log(this.login);
              }).catch(
                error=>{
                  console.log(error);
                  this.loggedIn=false;
                }
              );
    },
    async checkLogin(){
      await axios.get("/php/isLoggedin.php")
              .then(resp =>{
                this.login = resp.data;
                this.loggedIn = this.login.id>0;
                console.log(this.login);
              }).catch(
                error=>{
                  console.log(error);
                  this.loggedIn=false;
                }
              );
    },
    async procLogin(){
      await axios.post("/php/login.php",{username:this.user,password:this.pswd})
              .then(resp =>{
                this.login = resp.data;
                this.loggedIn = resp.data.isLoggedIn;
                console.log(this.login);
              }).catch(
                error=>{
                  console.log(error);
                  this.loggedIn=false;
                }
              );
    }
  }
  ,
  mounted() {
    icon.value = 'favicon.png';
    console.log("नमस्कार। ");
    this.checkLogin();
    
  },
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
