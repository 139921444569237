<template>
  <div class="hello">
    <div class="card text-start">
      <div class="card-body">
        <h4 class="card-title">Bliss Holidays Admin Panel</h4>
        <p class="card-text">Welcome, please go to "Manage Content" tab for editing content</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
